var React = require('react');
var ReactDOM = require('react-dom');
import { useEffect, useState } from 'react';

import ServiceItemsSlide from './Treatments/ServiceItemsSlide';
import TeamItemsRenderSlide from './Team/TeamItemsRenderSlide';
import BlogItemsRender from './Blog/BlogItemsRender';

const HomePage = () => {

    const [TeamItems, setTeamItems] = useState([]);
    const [ServicesItems, setServicesItems] = useState([]);
    const [BlogItems, setBlogItems] = useState([]);

    useEffect(() => {
        fetch("/ReactData/GetHomeServices")
            .then(res => res.json())
            .then(
                (result) => {
                    setServicesItems(result);
                }
            )
    }, [])

    useEffect(() => {
        fetch("/ReactData/GetHomeTeam")
            .then(res => res.json())
            .then(
                (result) => {
                    setTeamItems(result);
                }
            )
    }, [])

    useEffect(() => {
        fetch("/ReactData/GetBlogItems")
            .then(res => res.json())
            .then(
                (result) => {
                    setBlogItems(result);
                }
            )
    }, [])


    return (
        <div>
            <div className="auto_margin">
                <div className="slide_conti">
                    <ServiceItemsSlide ListOfItems={ServicesItems} />
                </div>
                <div className="slide_conti">
                    <TeamItemsRenderSlide ListOfItems={TeamItems} />
                </div>
                <iframe frameborder="0" height="270px" width="100%" allowfullscreen src="https://www.medreviews.co.il/provider/dr-brukcmayer-yoram/reviews-widget?show-header=true&slide-switch-interval=4500&bg=ffffff&controls-color="></iframe>
            </div>
            <div className="box1">
                <div className="auto_margin slide_conti slide_conti_blog">
                    <BlogItemsRender ListOfItems={BlogItems} />
                </div>
            </div>
        </div>
    );
}

export default HomePage;