var React = require('react');
import ReactHtmlParser from 'react-html-parser';

const ServiceItem = (props) => {

    const item = props.item;
    const url = item.FriendlyUrl == "השתלות-שיניים" ? "lp2" : item.FriendlyUrl == "יישור-שיניים-שקוף" ? "lp" : "treatments/" + item.FriendlyUrl;

    return (
        <li>
            <a href={url} className="service_item">
                <div className="icon">
                    {(item.Image ? <img src={item.Image} /> : "")}
                </div>
                <h2 className="title">
                    {item.Headline}
                </h2>
                <div className="content">
                    {ReactHtmlParser(item.Description)}
                </div>
                <div className="text_center">
                    <span className="button2 button">
                        לפרטים נוספים
                    </span>
                </div>
            </a>
        </li>
    )
}

export default ServiceItem;

