var React = require('react');
import TeamItem from './TeamItem';

const TeamItemsRender = (props) => {

    const items = props.ListOfItems;


    return (
        <div className="services_items_inner">
            <ul className="flex flex_wrap">
                {items.map(item =>
                    <TeamItem item={item} key={item.Id} />
                )}
            </ul>
        </div>
    )
}

export default TeamItemsRender;

//class Main extends React.Component {  
//    render() {  
//        return ( <> <h1> hello world dsad dsa</h1> </>)  
//    }  
//}  
//export default Main; 