var React = require('react');
import ReactHtmlParser from 'react-html-parser';

const TeamItem = (props) => {

    const item = props.item;


    return (
        <li>
            <a href={"team/" + item.FriendlyUrl} className="team_item transition">
                <div className="item_image">
                    <div className="image transition" style={{ backgroundImage: `url(${item.Image})` }}>
                    </div>
                </div>
                <div className="content">
                    <h2 className="title">
                        {item.Headline}
                    </h2>
                    <div className="text">
                        {ReactHtmlParser(item.Description)}
                    </div>
                </div>
            </a>
        </li>
    )
}

export default TeamItem;

