var React = require('react');
import TeamItem from './TeamItem';

const TeamItemsRender = (props) => {

    const items = props.ListOfItems;

    const handelScrollLeft = (element, slide, moves) => {
        const slider = slide;
        var button = element.target.closest('span');
        button.classList.add('disabled');

        for (let i = 0; i < moves; i++) {
            setTimeout(function () {
                slider.scrollLeft -= 1;
            }, 1 * i);
        }

        setTimeout(function () {
            button.classList.remove("disabled");
        }, moves * 1);
    }

    const handelScrollRight = (element, slide, moves) => {
        const slider = slide;
        var button = element.target.closest('span');
        button.classList.add('disabled');

        for (let i = 0; i < moves; i++) {
            setTimeout(function () {
                slider.scrollLeft += 1;
            }, 1 * i);
        }

        setTimeout(function () {
            button.classList.remove("disabled");
        }, moves * 1);
    }

    return (
        <div className="services_items item_margin_b_40">
            <div className="headline2">
                <h2>
                    צוות המרפאה
                </h2>
                <a href="/team" className="headline_link">
                    לכל הצוות
                </a>
            </div>
            <div className="slide_button_conti right">
                <span className="slide_button transition right" onClick={(e) => handelScrollRight(e, document.getElementById('slider2'), '246')}>
                    <span className="arrow arrow_right"></span>
                </span>
            </div>
            <div className="slide_button_conti left">
                <span className="slide_button transition left" onClick={(e) => handelScrollLeft(e, document.getElementById('slider2'), '246')}>
                    <span className="arrow arrow_left"></span>
                </span>
            </div>
            <ul className="flex slider" id="slider2">
                {items.map(item =>
                    <TeamItem item={ item } key={item.Id} />
                )}
            </ul>
        </div>
    )
}

export default TeamItemsRender;

//class Main extends React.Component {  
//    render() {  
//        return ( <> <h1> hello world dsad dsa</h1> </>)  
//    }  
//}  
//export default Main; 