var React = require('react');
import ServiceItem from './ServiceItem';

const ServiceItems = (props) => {

    const items = props.ListOfItems;


    return (
        <div className="services_items">
            <ul className="flex flex_wrap flex_space_center" >
                {items.map(item =>
                    <ServiceItem item={item} key={ item.Id }/>
                )}
            </ul>
        </div>
    )
}

export default ServiceItems;
