var React = require('react');
var ReactDOM = require('react-dom');
import { useEffect, useState } from 'react';

import TeamItemsRender from './TeamItemsRender';

const TeamPage = () => {

    const [TeamItems, setTeamItems] = useState([]);

    useEffect(() => {
        fetch("/ReactData/GetHomeTeam")
            .then(res => res.json())
            .then(
                (result) => {
                    setTeamItems(result);
                }
            )
    }, [])

    return (
        <div>
            <div className="auto_margin">
                
                <div className="team_items_conti">
                    <TeamItemsRender ListOfItems={TeamItems} />
                </div>
            </div>
        </div>
    );
}

export default TeamPage;