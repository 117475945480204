var React = require('react');
var ReactDOM = require('react-dom');
import { useEffect, useState } from 'react';

import ServiceItems from './ServiceItems';

const TreatmentsPage = () => {

    const [ServicesItems, setServicesItems] = useState([]);

    useEffect(() => {
        fetch("/ReactData/GetHomeServices")
            .then(res => res.json())
            .then(
                (result) => {
                    setServicesItems(result);
                }
            )
    }, [])


    return (
        <div>
            <div className="auto_margin">

                <div className="services">
                    <ServiceItems ListOfItems={ServicesItems} />
                </div>
            </div>
        </div>
    );
}

export default TreatmentsPage;