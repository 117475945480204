var React = require('react');
import BlogItem from './BlogItem';

const BlogItemsRender = (props) => {

    const items = props.ListOfItems;

    const handelScrollLeft = (element, slide, moves) => {
        const slider = slide;
        var button = element.target.closest('span');
        button.classList.add('disabled');

        for (let i = 0; i < moves; i++) {
            setTimeout(function () {
                slider.scrollLeft -= 1;
            }, 1 * i);
        }

        setTimeout(function () {
            button.classList.remove("disabled");
        }, moves * 1);
    }

    const handelScrollRight = (element, slide, moves) => {
        const slider = slide;
        var button = element.target.closest('span');
        button.classList.add('disabled');

        for (let i = 0; i < moves; i++) {
            setTimeout(function () {
                slider.scrollLeft += 1;
            }, 1 * i);
        }

        setTimeout(function () {
            button.classList.remove("disabled");
        }, moves * 1);
    }

    return (
        <div className="blog_items">
            <div className="headline2">
                <h2>
                    הבלוג שלנו
                </h2>
            </div>
            <div className="slide_button_conti right">
                <span className="slide_button transition right" onClick={(e) => handelScrollRight(e, document.getElementById('slider3'), '246')}>
                    <span className="arrow arrow_right"></span>
                </span>
            </div>
            <div className="slide_button_conti left">
                <span className="slide_button transition left" onClick={(e) => handelScrollLeft(e, document.getElementById('slider3'), '246')}>
                    <span className="arrow arrow_left"></span>
                </span>
            </div>
            <ul className="flex slider" id="slider3">
                {items.map((item, index) => (
                    <BlogItem item={item} key={index} />
                ))}
            </ul>
        </div>
    )
}

export default BlogItemsRender;

