var React = require('react');
import { useForm } from "react-hook-form";

const ContactForm = (props) => {

    const requiredField = "שדה חובה";
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data) => {

        fetch("/Contact/ContactSendlead2", {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                "Content-type": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(function (data) {
                if (data == "true") {
                    window.location = "/thanks";
                } else {
                    alert("הנתונים לא עברו יש צורך להזין את כל הנתונים");
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
                vue.$data.serverRequest = false;
            })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} id="leadForm">
            <input
                name="Url"
                hidden
                {...register("Url", {
                    required: requiredField,
                })}
                defaultValue={window.location}
            />
            <ul className="flex flex_wrap">
                <li className="flex_column_4 flex_item_space_10 box_sizing flex_mobile_items_1 flex_item_space_mobile_0 item_margin_b_15">
                    <input
                        name="Name"
                        type="text"
                        placeholder="שם מלא"
                        {...register("Name", {
                            required: requiredField,
                        })}
                        className={errors.Name && "error_field"}
                    />
                    <span className="field-validation-error">{errors.Name && errors.Name.message}</span>
                </li>
                <li className="flex_column_6 flex_item_space_10 box_sizing flex_mobile_items_1 flex_item_space_mobile_0 item_margin_b_15">
                    <input
                        name="Email"
                        type="email"
                        placeholder="כתובת מייל"
                        {...register("Email", {
                            required: requiredField,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "אימייל לא תקין"
                            }
                        })}
                        className={errors.Email && "error_field"}
                    />
                    <span className="field-validation-error">{errors.Email && errors.Email.message}</span>
                </li>
                <li className="flex_column_4 flex_item_space_10 box_sizing flex_mobile_items_1 flex_item_space_mobile_0 item_margin_b_15">
                    <input
                        name="Phone"
                        type="tel"
                        placeholder="טלפון"
                        {...register("Phone", {
                            required: requiredField,
                            pattern: {
                                value: /^(?:(?:(\+?972|\(\+?972\)|\+?\(972\))(?:\s|\.|-)?([1-9]\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})$/,
                                message: "מספר טלפון לא תקין"
                            }
                        })}
                        className={errors.Phone && "error_field"}
                    />
                    <span className="field-validation-error">{errors.Phone && errors.Phone.message}</span>
                </li>
                <li className="flex_column_6 flex_mobile_items_1 flex_item_space_mobile_0 item_margin_b_15">
                    <input
                        name="Reason"
                        type="text"
                        placeholder="סיבת הפנייה"
                        autoComplete="off"
                        {...register("Reason")}
                    />
                </li>
                <li className="flex_column_10 flex_mobile_items_1 flex_item_space_mobile_0 item_margin_b_15">
                    <textarea
                        name="Description"
                        type="text"
                        placeholder="תיאור הפנייה"
                        {...register("Description")}
                    />
                </li>
                <li className="flex_column_10 flex_mobile_items_1 flex_item_space_mobile_0 item_margin_b_15">
                    <div className="checkbox_conti_absolute">
                        <div>
                            <input
                                name="Newsletter"
                                type="checkbox"
                                {...register("Newsletter")}
                            />
                            <span></span>
                        </div>
                        <label>
                            שלחו לי מידע שימושי, הטבות ומבצעים (ניתן לבטל בכל עת)
                        </label>
                    </div>
                </li>
                <li className="flex_column_3 flex_mobile_items_1 flex_item_space_mobile_0" >
                    <button className="button1 button transition" id="submit_button">
                        <span>
                            שליחת טופס
                        </span>
                    </button>
                </li>
            </ul>
        </form>
    )

}

export default ContactForm;


