var React = require('react');


import ContactForm from './ContactForm';

const ContactPage = () => {

    return (
        <>
            <ContactForm />
        </>
    );
}

export default ContactPage;