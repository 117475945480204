var React = require('react');
var ReactDOM = require('react-dom');
import { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import { useForm } from "react-hook-form";

import HomePage from './components/HomePage';
import TeamPage from './components/Team/TeamPage';
import TreatmentsPage from './components/Treatments/TreatmentsPage';
import ContactPage from './components/Contact/ContactPage';

function App() {
    return (
        <div>
            <Switch>
                <Route path="/team">
                    <TeamPage />
                </Route>

                <Route path="/treatments">
                    <TreatmentsPage />
                </Route>

                <Route path="/contact">
                    <ContactPage />
                </Route>

                <Route path="/">
                    <HomePage />
                </Route>
            </Switch>
        </div>
    );
}


ReactDOM.render(
    <Router>
        <App />
    </Router>
    , document.getElementById('root'));